html {
    box-sizing: border-box;
    overflow: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#app {
    height: 100%;
    overflow: hidden;
    // https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
    overscroll-behavior-y: none;
}

#app {
    display: flex;
}

.punchcard-day-button {
    transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    will-change: transform;
    &:active {
        transform: scale3d(0.9, 0.9, 1);
    }
}

body {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.relative-parent {
    position: relative;
}

.btn {
    font-variant: all-small-caps;
}

.material-icons {
    font-variant: initial;
}

.opacity-fade {
    will-change: opacity;
    transition: opacity 0.3s ease-in;

    opacity: 0;

    &.in {
        opacity: 1;
    }
}

@keyframes fadeInAnim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// https://css-tricks.com/animating-with-clip-path/
@keyframes slideDownAnim {
    0% {
        clip-path: inset(0 0 100% 0);
    }

    100% {
        clip-path: inset(0);
    }
}

.fade-in {
    will-change: opacity;
    animation: 0.1s ease-in fadeInAnim;
}

.text-small {
    // iPhone 12 mini overflow...
    font-size: 0.88em !important;
}

footer.navbar {
    .btn {
        height: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .btn-active {
        font-weight: 700;
        .material-icons {
            transform: scale(1.2);
        }

        position: relative;

        ::after {
            content: '';
            display: block;
            top: -2px;
            left: 0;
            position: absolute;
            width: 100%;
            height: 1px;
            background: hsla(
                var(--s-link-color-dark-h),
                var(--s-link-color-dark-s),
                calc(var(--s-link-color-dark-l) + var(--s-link-color-dark-l-l)),
                var(--s-link-color-dark-a)
            );
        }
    }
    .material-icons {
        font-size: 1.8rem;
    }
}
