.card-body .weekday-buttons {
  -webkit-user-select: none;
  user-select: none;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  display: grid;
}

@media (max-width: 374.999px) {
  .card-body .weekday-buttons {
    grid-template-columns: repeat(6, 1fr);
  }

  .card-body .weekday-buttons > :first-child {
    display: none;
  }
}

html {
  box-sizing: border-box;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body, #app {
  height: 100%;
  overscroll-behavior-y: none;
  overflow: hidden;
}

#app {
  display: flex;
}

.punchcard-day-button {
  will-change: transform;
  transition: transform .3s cubic-bezier(.17, .67, .83, .67);
}

.punchcard-day-button:active {
  transform: scale3d(.9, .9, 1);
}

body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.relative-parent {
  position: relative;
}

.btn {
  font-variant: all-small-caps;
}

.material-icons {
  font-variant: initial;
}

.opacity-fade {
  will-change: opacity;
  opacity: 0;
  transition: opacity .3s ease-in;
}

.opacity-fade.in {
  opacity: 1;
}

@keyframes fadeInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDownAnim {
  0% {
    clip-path: inset(0 0 100%);
  }

  100% {
    clip-path: inset(0);
  }
}

.fade-in {
  will-change: opacity;
  animation: .1s ease-in fadeInAnim;
}

.text-small {
  font-size: .88em !important;
}

footer.navbar .btn {
  height: auto;
  flex-direction: column;
  flex: 1;
  display: flex;
}

footer.navbar .btn-active {
  font-weight: 700;
  position: relative;
}

footer.navbar .btn-active .material-icons {
  transform: scale(1.2);
}

footer.navbar .btn-active :after {
  content: "";
  width: 100%;
  height: 1px;
  background: hsla(var(--s-link-color-dark-h), var(--s-link-color-dark-s), calc(var(--s-link-color-dark-l)  + var(--s-link-color-dark-l-l)), var(--s-link-color-dark-a));
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

footer.navbar .material-icons {
  font-size: 1.8rem;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("material-icons.5d000324.woff2") format("woff2"), url("material-icons.130ad2eb.woff") format("woff");
}

.material-icons {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

/*# sourceMappingURL=index.8c96df94.css.map */
