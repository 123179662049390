.card-body .weekday-buttons {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
    -webkit-user-select: none;
    user-select: none;
}

@media (width < 375px) {
    .card-body .weekday-buttons {
        grid-template-columns: repeat(6, 1fr);

        > :first-child {
            display: none;
        }
    }
}
